
import React, { Fragment } from 'react';

import { Container } from '../Container';

const MobileOnly = (props) => (
  <Container display={['inherit', 'none']} {...props} />
);
const DesktopOnly = (props) => (
  <Container display={['none', 'inherit']} {...props} />
);
export const ResponsiveTab = ({ short, long }) => (
  <Fragment>
    <MobileOnly>{short}</MobileOnly>
    <DesktopOnly>{long}</DesktopOnly>
  </Fragment>
);
