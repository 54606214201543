import React from 'react';
import { useAPI } from '../../lib/fetch';
import { RouteComponentProps } from '@reach/router';

import { Card, Heading, Text, Button, Container } from '../../components';

import { reactionsLookup } from '../../assets/ligands';
import { useUser } from '../../context/User';


function Suggestion({ ligand }): JSX.Element {
  const { name, cas } = ligand;
  return <Container py={2} display="flex" alignItems="center" justifyContent="space-between">
    <Container overflow="hidden">
      <Text css={`text-overflow: ellipsis;`} noMargin bold>{name}</Text>
      <Text noMargin>CAS Number: {cas}</Text>
    </Container>
    <Button variant="primary" onClick={() => document.location.href = `https://www.sigmaaldrich.com/catalog/search?term=${cas}&interface=CAS%20No.`}>Buy now</Button>
  </Container>;
}

export function Job({ jobId }: RouteComponentProps & { jobId: string }): JSX.Element {
  const user = useUser();

  const { status, response } = useAPI<{ results: { id: string }[]; reaction: string }>(
    'GET',
    `/job/${jobId}`,
    '',
    user.authHeader,
  );
  return (
    <>
      <Card mt={4}>
        <Heading size="kilo">Results</Heading>
        {status === 'error' ? (
          <Text>
            Retrieving the results failed. The job might not have finished yet
            or you don't have sufficient permissions to access the job.
          </Text>
        ) : null}
        <Text><Text as="span" bold>Job ID:</Text> { jobId }</Text>
        <Text><Text as="span" bold>Reaction:</Text> { reactionsLookup[response?.reaction] }</Text>
      </Card>
      
      { status === 'success' && response.results ? (
          <Card mt={4}>{ response.results.map((r) => <Suggestion key={r.id} ligand={r} />) }</Card>
      ): null}
    </>
  );
}
