import React, { useState } from 'react';

import {
  SelectorGroup,
  Card,
  Heading,
  ButtonGroup,
  Button,
  Text,
  Input,
} from '../../components';

import { reactions as allReactions } from '../../assets/ligands';
import { useAPI, useQuery } from '../../lib/fetch';
import { useUser } from '../../context/User';
import { Coupon } from './Coupon';

interface ReactionOptions {
  reactions: string[];
  onSubmit?: (values: string[]) => void;
}

export default function Reaction({ reactions, onSubmit }: ReactionOptions): JSX.Element {
  const user = useUser();
  const [selected, setSelected] = useState<string[]>(reactions);

  const { response, revalidate } = useAPI<{ remaining: number }>('GET', '/plan', '', user.authHeader);

  if (!response) return null;

  return response && response.remaining === 0 ? <Coupon onSuccess={() => revalidate()} /> : (
    <>
      <Card mt={4}>
        <Heading size="kilo">
          Reactions
        </Heading>
        <Text>
          Please select the type of reaction you performed experiments on.
        </Text>
        <SelectorGroup
          options={allReactions.map(({ id, name }) => ({
            value: id,
            children: name,
          }))}
          value={selected}
          onChange={(values) => setSelected(values)}
        />
      </Card>
      <Card mt={4}>
        <Text>
          In the next step we will ask you to provide yield values for each
          ligand in the test set.
        </Text>
        <ButtonGroup>
          <Button
            disabled={!selected.length}
            variant="primary"
            onClick={() => onSubmit(selected)}
          >
            Next
          </Button>
        </ButtonGroup>
      </Card>
    </>
  );
}
