import { FormEvent, useCallback, useEffect, useState } from "react";
import { Card, Heading, Text, Input, ButtonGroup, Button } from "../../components";
import { useUser } from "../../context/User";
import { useAPI } from "../../lib/fetch";

export function Coupon({ onSuccess }: { onSuccess: () => void }): JSX.Element {
    const user = useUser();
    const [code, setCode] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const updateCode = useCallback((e: FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        setCode(val.toUpperCase().replace(/[^A-Z0-9]/g, ''));
    },[]);

    const { status, response } = useAPI(submitted ? 'POST' : null, `/coupon/${code}`, '', user.authHeader);

    useEffect(() => {
        if (status === 'error') {
            setSubmitted(false);
            setError('Code is not valid');
        } else if (status === 'success') {
            setError('');
            onSuccess && onSuccess();
        }
    }, [status]);

    return (
        <Card mt={4}>
            <Heading size="kilo">
                Provide Coupon Code
            </Heading>
            <Text>
                There are no credits left on your account. Please provide a coupon code to replenish your credits. You can find the coupon code on the container that was shipped to you.
            </Text>
            <Input label="Code" placeholder="0A0A0A" value={code} onChange={updateCode} validationHint={error} invalid={!!error} maxLength={8} minLength={8} />
            <ButtonGroup>
                <Button
                    disabled={submitted || code.length !== 8}
                    variant="primary"
                    onClick={() => {
                        setSubmitted(true);
                        setError('');
                    }}
                >
                    Apply
                </Button>
            </ButtonGroup>
        </Card>
    );
}
