import { light as circuit } from '@sumup/design-tokens';


type Colors = {
  '100': string;
  '200': string;
  '300': string;
  '400': string;
  '500'?: string;
  '700'?: string;
  '900'?: string;
};

export function createTheme({ primaryColors }: { primaryColors: Colors }) {
  const p = {};
  Object.keys(primaryColors).forEach((c) => (p[`p${c}`] = primaryColors[c]));
  const theme = {
    ...circuit,
    colors: {
      ...circuit.colors,
      ...p,
    },
    breakpoints: ['576px', '768px', '992px', '1200px'],
  };
  return theme;
}

export const richPurple: Colors = {
  '100': '#B9ADD3',
  '200': '#9684BD',
  '300': '#735BA7',
  '400': '#503291',
  '500': '#482D83',
  '700': '#382366',
  '900': '#281949',
};
export const vibrantMagenta: Colors = {
  '100': '#F7B1D5',
  '200': '#F38AC0',
  '300': '#EF63AB',
  '400': '#EB3C96',
  '500': '#D43687',
  '700': '#A52A69',
  '900': '#761E4B',
};
export const richBlue: Colors = {
  '100': '#9FC3DF',
  '200': '#6FA5CF',
  '300': '#3F87BF',
  '400': '#0F69AF',
  '500': '#0E5F9E',
  '700': '#0B4A7B',
  '900': '#083558',
};
export const vibrantCyan: Colors = {
  '100': '#ABE5EB',
  '200': '#81D8E1',
  '300': '#57CBD7',
  '400': '#2DBECD',
  '500': '#29ABB9',
  '700': '#208590',
  '900': '#175F67',
};
export const vibrantYellow: Colors = {
  '100': '#FFE9AD',
  '200': '#FFDE84',
  '300': '#FFD35B',
  '400': '#FFC832',
  '500': '#F3BE2F',
  '700': '#DDAC28',
  '900': '#806419',
};
export const vibrantGreen: Colors = {
  '100': '#DBEBB9',
  '200': '#C9E196',
  '300': '#B7D773',
  '400': '#A5CD50',
  '500': '#95B948',
  '700': '#749038',
  '900': '#536728',
};
export const richRed: Colors = {
  '100': '#F5A5B9',
  '200': '#F07896',
  '300': '#EB4B73',
  '400': '#E61E50',
  '500': '#CF1B48',
  '700': '#A11538',
  '900': '#730F28',
};
export const richBlack: Colors = {
  '100': '#93939D',
  '200': '#6A6A73',
  '300': '#494953',
  '400': '#1B1B25',
};
export const sensitiveGrey: Colors = {
  '100': '#FFFFFF',
  '200': '#FFFFFF',
  '300': '#F8F8FC',
  '400': '#F8F8FC',
  '500': '#F3F3F7',
  '700': '#EFEFF1',
  '900': '#E9E9ED',
};
export const richGreen: Colors = {
  '100': '#89CDAF',
  '200': '#72C39F',
  '300': '#5AB98F',
  '400': '#149B5F',
  '500': '#0D6C42',
  '700': '#0C5D39',
  '900': '#094D2F',
};
export const highlight = {
  green: '#B4DC96',
  yellow: '#FFDCB9',
  blue: '#96D7D2',
  pink: '#E1C3CD',
};
