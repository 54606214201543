import React, { useState, useMemo } from 'react';

import {
  Card,
  Heading,
  ButtonGroup,
  Button,
  Text,
  Container,
} from '../../components';

import Table from './Table';

import Histogram from './Histogram';

interface YieldsOptions {
  onSubmit?: (values: any) => void;
  onCancel?: () => void;
  reactions: string[];
  values: {
    [key: string]: {
      [key: string]: number;
    };
  };
}

export default function({
  values: input = {},
  onCancel,
  onSubmit,
  reactions,
}: YieldsOptions) {
  const [values, setValues] = useState(input);
  const [editMode, setEditMode] = useState(reactions);

  const valuesEntered = useMemo(
    () => editMode.length === 0,
    [editMode],
  );

  return (
    <>
      <Card mt={4}>
        <Heading size="kilo">Yields</Heading>
        <Text>Please provide the yields for each ligand and reaction.</Text>

        <ButtonGroup>
          <Button variant="secondary" onClick={onCancel}>
            Back
          </Button>
        </ButtonGroup>
      </Card>
      {reactions.map((r) => (
        <Container mt={4}>
          {editMode.includes(r) ? (
            <Table
              reaction={r}
              values={values[r]}
              onSubmit={(v) => {
                setValues({ ...values, [r]: v });
                setEditMode(editMode.filter((i) => i !== r));
              }}
            />
          ) : (
            <Histogram
              reaction={r}
              values={values[r]}
              onSubmit={() => setEditMode([...editMode, r])}
            />
          )}
        </Container>
      ))}
      <Card mt={4}>
        <Text>
          In the next step we will provide a preliminary analysis of the quality
          of the provided values.
        </Text>
        <ButtonGroup>
          <Button variant="secondary" onClick={onCancel}>
            Back
          </Button>
          <Button
            disabled={!valuesEntered}
            variant="primary"
            onClick={() => onSubmit(values)}
          >
            Next
          </Button>
        </ButtonGroup>
      </Card>
    </>
  );
}
