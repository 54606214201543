import React, { useContext, useMemo } from 'react';

import { UserContext } from '../../context';

import { useQuery } from '../../lib/fetch';

import {
  Card,
  Heading,
  Text,
} from '../../components';

import { reactionsLookup } from '../../assets/ligands';
import { useUser } from '../../context/User';

interface ResultsOptions {
  onSubmit?: (values: string[]) => void;
  onCancel?: (values: string[]) => void;
  reactions: {
    [key: string]: {
      [key: string]: number;
    };
  };
}

function Job({ reaction, values }) {
  const { authHeader } = useUser();
  const args = useMemo(() => ({
    reaction,
    values,
  }), [reaction, values]);
  const { status, response } = useQuery<{ jobId: string; }>('POST', 'https://wzfcmle9o6.execute-api.us-east-2.amazonaws.com/job', args, authHeader);

  return (<Text>
    <Text as="span" bold>{reactionsLookup[reaction]}:</Text> { status === 'success' ? response.jobId : 'loading...'}
    </Text>);
}

export default function Results({ onCancel, onSubmit, reactions }: ResultsOptions): JSX.Element {
  const user = useContext(UserContext);

  return (
    <>
      <Card mt={4}>
        <Heading size="kilo">Results</Heading>
        <Text>
          Please allow several minutes for the model training and the suggested
          ligands to be returned.
        </Text>
        <Text>
          We will email the results to <strong>{user && user.info.email}</strong>.
        </Text>
      </Card>
      <Card mt={4}>
        <Heading size="kilo">Job IDs</Heading>
        <Text>Each reaction is assigned its own unique job ID.</Text>
        {Object.keys(reactions).map((r) => (
          <Job reaction={r} key={r} values={reactions[r]} />
        ))}
      </Card>
    </>
  );
}
