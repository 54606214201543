
function transparentize(alpha, rgb) {
  const colors = [rgb.slice(1, 3), rgb.slice(3, 5), rgb.slice(5, 7)].map((i) => parseInt(i, 16));
  return `rgba(${colors.join(', ')}, ${1-alpha})`;
}

export const shadowBorder = (color, borderSize = '1px') => `
  box-shadow: 0px 0px 0px ${borderSize} ${color};
`;

export const shadowGround = ({ theme }) => `
  box-shadow: 0 0 0 2px ${transparentize(0.97, shadow)};
`;

const shadow = '#0c0f14';

export const shadowSingle = ({ theme }) => `
  box-shadow: 0 0 0 1px ${transparentize(0.98, shadow)},
    0 0 1px 0 ${transparentize(0.94, shadow)},
    0 2px 2px 0 ${transparentize(0.94, shadow)};
`;

export const shadowDouble = ({ theme }) => `
  box-shadow: 0 0 0 1px ${transparentize(0.98, shadow)},
    0 2px 2px 0 ${transparentize(0.94, shadow)},
    0 4px 4px 0 ${transparentize(0.94, shadow)};
`;

export const shadowTriple = ({ theme }) => `
  box-shadow: 0 0 0 1px ${transparentize(0.98, shadow)},
    0 4px 4px 0 ${transparentize(0.94, shadow)},
    0 8px 8px 0 ${transparentize(0.94, shadow)};
`;
