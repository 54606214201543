import { useState, useEffect, useCallback } from 'react';
import querystring from 'querystring';

type QueryStatus = 'success' | 'loading' | 'error'

type Method = 'POST' | 'GET' | 'PUT' | 'DELETE'

type Body = { [key: string]: any }

export async function request<T>(
  input: string,
  args: {
    method?: Method;
    body?: Body | string;
    headers?: { [key: string]: string };
    queryParameters?: { [key: string]: string };
  } = {}
): Promise<{ response: Response; body: T | null; error: Error | null }> {
  const qs = querystring.stringify(args.queryParameters);
  const res = await fetch(qs ? `${input}?${qs}` : input, {
    headers: {
      'content-type': 'application/json',
      ...args.headers,
    },
    credentials: 'omit',
    mode: 'cors',
    method: args.method,
    body: args.method === 'GET' ? undefined : typeof args.body === 'object' ? JSON.stringify(args.body) : args.body,
  });
  const text = await res.text();
  try {
    const body = text.length ? JSON.parse(text) : text;
    return { response: res, body: body, error: null };
  } catch (err) {
    return { response: res, body: null, error: err };
  }
}

export function useQuery<T>(
  method: Method | null,
  path: string,
  body?: Body | string,
  headers?: { [key: string]: string },
) {
  const [response, setResponse] = useState<T | null>(null);
  const [status, setStatus] = useState<QueryStatus | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(() => {
    setStatus(null);
    if (method === null) {
      setResponse(null);
      setError(null);
      return;
    }
    setStatus('loading');
    request<T>(path, { method, body, headers })
      .then(
        ({ response: res, body, error: err }) => {
          if (res.ok) {
            setResponse(body);
            setStatus('success');
          } else {
            setError(err);
            setStatus('error');
          }
        },
        (err) => {
          setError(err);
          setStatus('error');
        }
      )
      .catch((e) => {
        console.error(e);
      });
  }, [method, path, body, headers]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { status, error, response, revalidate: fetchData };
}

export function useAPI<T>(
  method: Method | null,
  path: string,
  body?: Body | string,
  headers?: { [key: string]: string },
) {
  return useQuery<T>(method, `https://wzfcmle9o6.execute-api.us-east-2.amazonaws.com${path}`, body, headers);
}