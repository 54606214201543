import React, { useState, useMemo, useRef } from 'react';
import styled from '@emotion/styled';

import {
  Card,
  Heading,
  ButtonGroup,
  Button,
  Text,
  Table,
  Input,
} from '../../components';

// import { histogram, thresholdSturges } from 'd3-array';

import { ligands, reactionsLookup } from '../../assets/ligands';


const NoBottomRadius = styled('div')`
  > div {
    border-radius: 4px 4px 0 0;
  }
`;
const NoTopRadius = styled('div')`
  > div {
    border-radius: 0 0 4px 4px;
  }
`;

const LightText = styled(Text)`
  color: #999;
`;

function Ligand({ name, cas }) {
  return (
    <>
      <Text noMargin>
        {name}
      </Text>
      <LightText noMargin size="kilo">
        CAS {cas}
      </LightText>
    </>
  );
}

function YieldInput({ onChange, value: val }) {
  const [value, setValue] = useState(val ?? 0);

  onChange(parseFloat(value));

  const cb = useMemo(
    () => (event) => {
      const val = event.target.value.replace(/,/g, '.');
      setValue(val);
      onChange(parseFloat(val));
    },
    [onChange],
  );

  const onKeyPress = (event) => {
    const key = event.key;
    const code = key.charCodeAt(0);
    if (code > 47 && code < 58) return;
    if (key === ',' || key === '.') return;
    event.preventDefault();
  };

  (window as any).__DEV__ = true;

  return (
    <Input
      label=""
      hideLabel
      noMargin
      placeholder="0"
      textAlign="right"
      value={value}
      onKeyPress={onKeyPress}
      onChange={cb}
      onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => e.currentTarget.select()}
    />
  );
}

const headers = [
  { children: 'ID', align: 'right' },
  'Ligand',
  { children: 'Yield', align: 'right' },
];

interface TableOptions {
  values: {
    [key: string]: number;
  };
  reaction: string;
  onSubmit: (values) => void;
}

export default function({ values: input = {}, reaction, onSubmit }: TableOptions) {
  // const [values, setValues] = useState({});
  const values = useRef(input);

  const rows = useMemo(
    () =>
      ligands.map(({ id, cas, name }) => [
        { children: <Text noMargin>{id}</Text>, align: 'right' },
        { children: <Ligand name={name} cas={cas} /> },
        {
          children: <YieldInput value={values.current[id]} onChange={(v) => (values.current[id] = v)} />,
        },
      ]),
    [values],
  );

  return (
    <>
      <NoBottomRadius>
        <Card>
          <Heading size="kilo">{reactionsLookup[reaction]}</Heading>
          <Text>
            Please enter the yield values for the {reactionsLookup[reaction]}{' '}
            and press{' '}
            <Text as="span" bold>
              Save
            </Text>{' '}
            at the bottom of the list.
          </Text>
        </Card>
      </NoBottomRadius>
      <Table condensed headers={headers} rows={rows} />
      <NoTopRadius>
        <Card>
          <ButtonGroup>
            <Button variant="primary" onClick={() => onSubmit(values.current)}>
              Save
            </Button>
          </ButtonGroup>
        </Card>
      </NoTopRadius>
    </>
  );
}
