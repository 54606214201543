import React, { useMemo } from 'react';

import { useTheme } from 'emotion-theming';

import { Chart } from 'react-charts';

import { histogram } from 'd3-array';

import {
  Card,
  Heading,
  ButtonGroup,
  Button,
  Text,
  Container,
} from '../../components';

import { ligands, reactionsLookup } from '../../assets/ligands';

const axes = [
  { primary: true, type: 'ordinal', position: 'bottom', show: false },
  {
    position: 'left',
    type: 'linear',
    show: true,
    hardMin: 0,
    format: (d) => parseFloat(d).toFixed(0),
  },
];

const series = {
  type: 'bar',
};

interface HistogramOptions {
  reaction: string;
  values: {
    [key: string]: number;
  };
  onSubmit?: () => void;
}

export default function({ reaction, values, onSubmit }: HistogramOptions) {
  const theme: any = useTheme();
  const bins = useMemo(
    () =>
      histogram().thresholds(Array.from({ length: 8 }, (i, j) => j * 12.5))(
        Object.values(values),
      ),
    [values],
  );

  const data = useMemo(
    () => [
      {
        label: 'Histogram',
        data: bins.map((bin, idx) => [`Bin ${idx + 1}`, bin.length]),
      },
    ],
    [bins],
  );

  return (
    <Card>
      <Container display={['block', 'flex']} justifyContent="space-between">
        <Container>
          <Heading size="kilo">{reactionsLookup[reaction]}</Heading>
          <Text>
            {Object.values(values).filter((i) => i).length} results entered.
          </Text>
          <Text>
            {ligands.length - Object.values(values).filter((i) => i > 0).length}{' '}
            missing.
          </Text>
          <Text>
            Predictive power of the provided input is{' '}
            <Text as="span" bold>
              OK
            </Text>
            .
          </Text>
        </Container>
        <Container width={[1, 250]} height={150} my={20}>
          <Chart
            axes={axes}
            series={series}
            data={data}
            tooltip
            getSeriesStyle={() => ({ color: theme.colors.n500 })}
          />
        </Container>
      </Container>
      <ButtonGroup>
        <Button variant="secondary" onClick={onSubmit}>
          Edit
        </Button>
      </ButtonGroup>
    </Card>
  );
}
