import React, { useState } from 'react';
import styled from '@emotion/styled';
import { RouteComponentProps } from '@reach/router';
import { css } from '@emotion/core';
import {
  Tab,
  TabList,
  TabPanel,
  ResponsiveTab,
} from '../../components';

import { Reaction, Yields, Results } from '../../views';

const TabsCard = styled('div')`
  width: 100%;
  > div {
    outline: none;
  }
`;

export const Wizard = (props: RouteComponentProps) => {
  const [reactions, setReactions] = useState([]);
  const [values, setValues] = useState({});
  const [openTab, setOpenTab] = useState(0);

  return (
    <TabsCard>
      <TabList className="">
        <Tab
          key="reaction"
          selected={openTab === 0}
          css={css`
            cursor: default;
          `}
        >
          <ResponsiveTab short="Reactions" long="Select Reactions" />
        </Tab>
        <Tab
          key="yields"
          selected={openTab === 1}
          css={css`
            cursor: default;
          `}
        >
          <ResponsiveTab short="Yields" long="Enter Yields" />
        </Tab>
        <Tab
          key="results"
          selected={openTab === 2}
          css={css`
            cursor: default;
          `}
        >
          <ResponsiveTab short="Results" long="Review Results" />
        </Tab>
      </TabList>
      {openTab === 0 && (
        <TabPanel>
          <Reaction
            reactions={reactions}
            onSubmit={(r) => {
              setReactions(r);
              setOpenTab(1);
            }}
          />
        </TabPanel>
      )}
      {openTab === 1 && (
        <TabPanel>
          <Yields
            reactions={reactions}
            values={values}
            onSubmit={(v) => {
              setValues(v);
              setOpenTab(2);
            }}
            onCancel={() => setOpenTab(0)}
          />
        </TabPanel>
      )}
      {openTab === 2 && (
        <TabPanel>
          <Results
            reactions={values}
            onSubmit={() => setOpenTab(2)}
            onCancel={() => setOpenTab(1)}
          />
        </TabPanel>
      )}
    </TabsCard>
  );
};
