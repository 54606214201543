import { Container } from '../../components';

import styled from '@emotion/styled';

export const HeaderTitle = styled(Container)`
  font-size: 1.3em;
  text-transform: uppercase;
  
`;

export const Header = styled(Container)`
  label: header;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 64px;
  background-color: ${({ theme }: any) => theme.colors.n900};
  padding: ${({ theme }: any) => theme.spacings.mega};
  z-index: ${({ theme }: any) => theme.zIndex.header};
  position: sticky;
  color: ${({ theme }: any) => theme.colors.n100};
`;
