import React from 'react';
import { Router } from '@reach/router';
import { ThemeProvider } from 'emotion-theming';
import {
  BaseStyles,
  Container,
} from './components';

import { Wizard, Job, Header } from './views';

import { createTheme, richPurple } from './themes/m';

import { UserContextProvider } from './context';

const theme = createTheme({ primaryColors: richPurple });

const { PUBLIC_URL = '/' } = process.env;

export default function App(): JSX.Element {
  return (
    <UserContextProvider autoLogin>
      <ThemeProvider theme={theme}>
        <BaseStyles
          css={`
        body {
          background-color: ${theme.colors.n100};
        }
      `}
        />
        <Header />
        <Container
          width={[1, 680]}
          mx="auto"
          display="flex"
          alignItems="center"
          flexDirection="column"
          pb={20}
          mt={20}
        >
          <Router basepath={PUBLIC_URL} style={{ width: '100%' }}>
            <Wizard exact path='/' />
            <Job path='/job/:jobId' />
          </Router>
        </Container>
      </ThemeProvider>
    </UserContextProvider>
  );
}
