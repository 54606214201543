import React, { useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import SelectorGroup from '@sumup/circuit-ui/dist/es/components/SelectorGroup';

const FixLabel = styled('div')`
  ${({ theme }: { theme: any }) => css`
input:checked + label {
    background-color: ${theme.colors.n100} !important;
  }
}`}
`;

interface SelectorGroupOptions {
  value: string[];
  options: {
    value: string;
    children: string;
  }[];
  onChange?: (values: string[]) => void;
  width?: any;
}

export default function({ value, options, onChange, width }: SelectorGroupOptions) {
  const [selected, setSelected] = useState(value);

  const onSelectorChange = (event) => {
    const val = event.target.value;
    let changed = [];
    if (selected.includes(val)) {
      changed = selected.filter((v) => v !== val);
    } else {
      changed = [val];
    }
    setSelected(changed);
    if (onChange) {
      onChange(changed);
    }
  };

  return (
    <FixLabel>
      <SelectorGroup
        label=""
        multiple
        value={selected}
        options={options}
        onChange={onSelectorChange}
      />
    </FixLabel>
  );
}
