import styled from '@emotion/styled';

import shouldForwardProp from '@styled-system/should-forward-prop';

import { layout, space, flexbox } from 'styled-system';


export const Container = styled('div', { shouldForwardProp })({

}, layout, space, flexbox);
