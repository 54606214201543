import React, { useContext } from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { UserContext } from '../../context';

import { Header, HeaderTitle, Container } from '../../components';

import { ReactComponent as LogoIcon } from '../../assets/logo.svg';

const Logo = styled(LogoIcon)`
  ${({ theme }: any) => css`
    width: 50px;
    display: block;
    path {
      fill: ${theme.colors.white};
    }
  `};
`;

export default function() {
  const user = useContext(UserContext);
  return (
    <Header display="flex" justifyContent="space-between">
      <Container display="flex">
        <Logo />
        <HeaderTitle px={4}><strong>Cat</strong>Predictor</HeaderTitle>
      </Container>
      <Container>{ user && user.info?.email }</Container>
    </Header>
  );
}
