export const reactions = [
  {
    id: "suzuki",
    name: "Suzuki Coupling",
  },
  {
    id: "bh",
    name: "Buchwald-Hartwig Amination",
  },
];

export const reactionsLookup = {};
reactions.forEach((r) => (reactionsLookup[r.id] = r.name));

export const ligands: {
  id: number;
  name: string;
  cas: string;
}[] = [
  { id: 3, name: "SPhos", cas: "657408-07-6, 1356823-31-8 (1S)" },
  { id: 15, name: "P(2OMePh)3", cas: "4731-65-1" },
  { id: 30, name: "PtBu2Cy", cas: "436865-11-1" },
  { id: 62, name: "P(4OMePh)3", cas: "855-38-9" },
  { id: 68, name: "PCy2Ph", cas: "6476-37-5" },
  { id: 69, name: "PCy2(4NMe2Ph), CyAtaPhos", cas: "40438-64-0" },
  { id: 79, name: "PiPr3", cas: "6476-36-4" },
  { id: 84, name: "PallylPh2", cas: "2741-38-0" },
  { id: 89, name: "tBuBrettPhos", cas: "1160861-53-9" },
  { id: 102, name: "BrettPhos", cas: "1070663-78-3" },
  { id: 104, name: "JackiePhos = (35CF3)2PhBrettPhos", cas: "1160861-60-8" },
  { id: 116, name: "PPh2(4-NMe2-Ph), PhAtaPhos", cas: "739-58-2" },
  { id: 130, name: "PPh2(2-OMe-Ph)", cas: "53111-20-9" },
  { id: 139, name: "P(OEt)Ph2", cas: "719-80-2" },
  { id: 148, name: "P(OEt)3", cas: "122-52-1" },
  { id: 217, name: "Ph,pTol2 Diazaphosphinane", cas: "72897-07-5" },
  { id: 239, name: "P(nOct)3", cas: "4731-53-7" },
  { id: 251, name: "PAllyl3", cas: "16523-89-0" },
  { id: 280, name: "tBuMePhos", cas: "255837-19-5" },
  { id: 327, name: "Cy-BippyPhos", cas: "1021176-69-1" },
  { id: 329, name: "CM-Phos", cas: "1067883-58-2" },
  { id: 338, name: "PAd2(2-piperidyl-Ph)", cas: "1237588-13-4" },
  { id: 340, name: "AdBippyphos", cas: "1239478-87-5" },
  { id: 351, name: "PEt2(4-NMe2-Ph), Et-AmPhos", cas: "17005-57-1" },
  {
    id: 401,
    name: "exo PPh,NTs Azaphosphabicycloheptane",
    cas: "1621906-60-2",
  },
  { id: 449, name: "P(NEt2)3", cas: "2283-11-6" },
  {
    id: 458,
    name: "PPh2(1-Isochinolin-2Nap)",
    cas: "149245-03-4 (no stereo), 149341-34-4 (R), 149245-03-4 (S)",
  },
  { id: 487, name: "PPh (4-(2,2-(CF3)2-F7pent)-Ph)2", cas: "322647-83-6" },
  { id: 640, name: "PNEt2_Benzodioxaphosphepine", cas: "82372-35-8" },
  { id: 644, name: "POPh_5,5-dimethyldioxaphosphinane", cas: "3057-08-7" },
  { id: 648, name: "P(OtBu)2N(iPr)2", cas: "137348-86-8" },
  { id: 650, name: "P(OBn)2NEt2", cas: "67746-43-4" },
];

export const ligandsLookup = {};
ligands.forEach((r) => (ligandsLookup[r.id] = r.name));
