import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import querystring from 'querystring';

import { Sha256 } from '@aws-crypto/sha256-browser';
import { randomValues } from '@aws-crypto/random-source-browser';

import { useQuery } from '../lib/fetch';

const clientId = process.env.REACT_APP_CLIENT_ID || '';

function generateVerifier(size = 16): Promise<Uint8Array> {
  return randomValues(size);
}

function sha256(arr: string | Uint8Array): Promise<Uint8Array> {
  const hash = new Sha256();
  hash.update(arr);
  return hash.digest();
}

function base64encode(str: Uint8Array): string {
  return btoa(String.fromCharCode(...((str as unknown) as number[])))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export interface UserSession {
  accessToken: string;
  idToken: string;
  expires: number;
}

export interface UserInfo {
  email: string;
  givenName?: string;
  familyName?: string;
  sub: string;
}

function isObject(args: unknown): args is Record<string, unknown> {
  return args !== undefined && args !== null && typeof args === 'object';
}

function isSession(args: unknown): args is UserSession {
  return (
    isObject(args) &&
    typeof args.accessToken === 'string' &&
    typeof args.idToken === 'string' &&
    typeof args.expires === 'number' &&
    args.expires > Date.now()
  );
}

interface TokenResponse {
  access_token: string;
  refresh_token: string;
  id_token: string;
  token_type: 'Bearer';
  expires_in: number;
}

interface IdpResponse {
  code: string;
}

interface IdpErrorResponse {
  error: string;
  error_description: string;
}

function isTokenResponse(args: unknown): args is TokenResponse {
  return (
    isObject(args) &&
    typeof args.access_token === 'string' &&
    typeof args.refresh_token === 'string' &&
    typeof args.id_token === 'string' &&
    typeof args.token_type === 'string' &&
    args.token_type === 'Bearer' &&
    typeof args.expires_in === 'number'
  );
}

function isIdpResponse(args: unknown): args is IdpResponse {
  return isObject(args) && typeof args.code === 'string';
}

function isIdpErrorResponse(args: unknown): args is IdpErrorResponse {
  return (
    isObject(args) &&
    typeof args.error === 'string' &&
    typeof args.error_description === 'string'
  );
}

export const UserContext = createContext<{
  info?: UserInfo;
  session?: UserSession;
  login?: () => void;
  logout?: () => void;
  authHeader?: {
    [key: string]: string;
  };
}>({});

function useSessionStorage<T>(
  key: string,
  pred: (arg: unknown) => arg is T
): [T | undefined, (item: T) => void, () => void] {
  const [item, setItem] = useState<T>();

  const updateStorage = useMemo(
    () => (item: T): void => {
        window.sessionStorage.setItem(key, JSON.stringify(item));
        setItem(item);
    },
    [key]
  );

  const clearSession = useMemo(
    () => (): void => {
      window.sessionStorage.removeItem(key);
      setItem(undefined);
    },
    [key]
  );

  if (!item) {
    try {
      const item = window.sessionStorage.getItem(key);
      if (item) {
        const session = JSON.parse(item);
        if (pred(session)) {
          setItem(session);
          return [session, updateStorage, clearSession];
        }
      }
    } catch (err) {
      // Nothing to do here
    }
  }

  return [item, updateStorage, clearSession];
}

export function UserContextProvider({
  autoLogin = false,
  children,
}: {
  autoLogin?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  const [session, updateSession, clearSession] = useSessionStorage(
    'session',
    isSession
  );

  const [code, setCode] = useState<string>();

  const [key, setKey, clearKey] = useSessionStorage(
    'pkceKey',
    (arg: unknown): arg is string => typeof arg === 'string'
  );

  const [entrypoint, setEntrypoint, clearEntrypoint] = useSessionStorage(
    'entrypoint',
    (arg: unknown): arg is string => typeof arg === 'string'
  );

  const [userInfo, setUserInfo] = useState<UserInfo>();

  const login = useCallback(async (): Promise<void> => {
    const newKey = await generateVerifier();
    const encodedKey = base64encode(newKey);
    setKey(encodedKey);
    // make sure to keep query string and hash
    setEntrypoint(document.location.href.slice(document.location.origin.length));
    const challenge = base64encode(await sha256(encodedKey));

    document.location.href =
      'https://login.emddigital.com/oauth2/authorize?' +
      querystring
        .stringify({
          client_id: clientId,
          response_type: 'code',
          scope: 'openid email',
          redirect_uri: `${document.location.origin}/auth`,
          code_challenge_method: 'S256',
          code_challenge: challenge,
        });
  }, [setEntrypoint, setKey]);

  const logout = useCallback((): void => {
    clearSession();
    setUserInfo(undefined);
    document.location.href =
      'https://login.emddigital.com/logout?' +
      querystring
        .stringify({
          client_id: clientId,
          logout_uri: `${document.location.origin}/logout`,
        });
  }, [clearSession]);

  const { status: tokenStatus, response: tokenResponse } = useQuery<
    TokenResponse
  >(
    code && key ? 'POST' : null,
    'https://login.emddigital.com/oauth2/token',
    querystring.stringify({
      grant_type: 'authorization_code',
      client_id: clientId,
      code_verifier: key,
      code,
      redirect_uri: `${document.location.origin}/auth`,
    }),
    useMemo(
      () => ({
        'content-type': 'application/x-www-form-urlencoded',
      }),
      []
    )
  );

  useEffect(() => {
    if (!session && tokenStatus === 'success' && isTokenResponse(tokenResponse)) {
      clearKey();
      updateSession({
        accessToken: tokenResponse.access_token,
        idToken: tokenResponse.id_token,
        expires: Date.now() + tokenResponse.expires_in * 1000,
      });
      if (code && entrypoint) {
        clearEntrypoint();
        document.location.replace(entrypoint);
      }
    }
  }, [session, tokenStatus, tokenResponse, entrypoint, code, clearEntrypoint, clearKey, updateSession]);

  const { status: userInfoStatus, response: userInfoResponse } = useQuery<any>(
    session && !code ? 'GET' : null,
    'https://login.emddigital.com/oauth2/userinfo',
    '',
    useMemo(
      () => ({
        authorization: `Bearer ${session?.accessToken}`,
      }),
      [session]
    )
  );

  if (session && userInfoStatus === 'error') clearSession();

  if (!userInfo && userInfoStatus === 'success') {
    setUserInfo({
      email: userInfoResponse.email,
      familyName: userInfoResponse.family_name,
      givenName: userInfoResponse.given_name,
      sub: userInfoResponse.sub,
    });
  }

  useEffect(() => {
    if (session) {
      if (userInfoStatus === 'error') clearSession();
      return;
    }
    if (
      document.location.pathname.split('/').pop() === 'auth' &&
      document.location.search.length > 1
    ) {
      const idpResponse = querystring.parse(document.location.search.slice(1));

      if (isIdpResponse(idpResponse)) {
        setCode(idpResponse.code);
        return;
      } else if (isIdpErrorResponse(idpResponse)) {
        return;
      }
    } else if (autoLogin) login();
  }, [session, autoLogin, clearSession, login, userInfoStatus]);

  return (
    <UserContext.Provider
      value={useMemo(() => {
        return {
          info: userInfo,
          session: session,
          login: !session || !userInfo ? login : undefined,
          logout: logout,
          authHeader: session ? {
            Authorization: `Bearer ${session.accessToken}`,
          } : {},
        };
      }, [session, userInfo, login, logout])}
      children={!autoLogin || userInfo ? children : null}
    />
  );
}

export function useUser() {
  return useContext(UserContext);
}
