import { shadowSingle } from '../utils';

import styled from '@emotion/styled';
import { layout, space, flexbox } from 'styled-system';

export const Card = styled('div')({
  label: 'card',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '16px 24px',
  width: '100%',
}, shadowSingle, ({ theme }: any) => ({
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.mega,
}), layout, space, flexbox);
